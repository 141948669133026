<template>
  <div
    class="page partners"
    :loaded="Boolean(partners) && Boolean(page)"
  >
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.page_title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.subtitle.replace(/\n/g, '<br>')"></h3>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="page">
      <div class="wrapper">
        <div class="section-footer">
          <p v-html="page.description.replace(/\n/g, '<br>')"></p>
        </div>
      </div>
    </section>

    <section class="section partners-list" v-if="partners">
      <div class="wrapper">
        <div class="container">
          <a
            class="partner"
            v-for="partner in partners"
            :href="partner.link"
            target="_blank"
            rel="noopener"
            :key="partner.id"
          >
            <img
              :src="partner.image"
              :alt="partner.name"
              class="partner-image"
            />
            <i class="ic ic-open-link-b"></i>
          </a>
        </div>
      </div>
    </section>

    <ContactForm />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "Partners",
  components: {
    Icon,
    ContactForm,
  },
  data() {
    return {
      partners: null,
      page: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.partnersRef) this.partnersRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("partners")
        .onSnapshot((page) => {
          this.page = {
            page_title: "",
            subtitle: "",
            image: "",
            ...page.data(),
          };
        });
      this.partnersRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}partners`)
        .orderBy("order")
        .onSnapshot((partners) => {
          this.partners = partners.docs.map((doc) => {
            const partner = doc.data();
            const link = partner.link;
            return {
              ...partner,
              link: /^https?:\/\//.test(link) ? link : 'http://' + link,
            };
          });
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.partnersRef) this.partnersRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 64px;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 26.56%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 56px 96px;
  border-radius: 12px;
}
.top-section .container .content .logo {
  display: block;
  width: 178px;
  position: absolute;
  top: 64px;
  left: 96px;
}
.top-section .container .content h1 {
  font-size: 128px;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 64px;
  line-height: 1.1625;
}
.top-section .container .content p {
  font-size: 18px;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 24px;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.partners-list .container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 32px;
}

.partners-list .partner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(25% - 24px);
  height: 246px;
  border-radius: 12px;
  padding: 48px 64px;
  text-align: center;
  transition: all .2s ease-out;
}

#app[no-touch] .partners-list .partner:hover {
  box-shadow: 0px 24px 32px 0px rgba(63, 61, 86, 0.08);
  transform: translateY(-8px);
}

.partners-list .partner .ic {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  color: #0A7E8C;
}

#app[no-touch] .partners-list .partner:hover .ic {
  opacity: 1;
}

.partners-list .partner img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1280px), screen and (max-height: 760px) {
  .page-header .wrapper {
    padding: 48px 96px;
    background-image: none;
    border-radius: 0;
  }
  .partners-list .partner {
    width: calc(33.333333% - 24px);
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 840px) {
  .partners-list .partner {
    height: 290px;
  }
  .partners-list .partner {
    width: calc(50% - 24px);
  }
}

@media screen and (max-width: 650px) {
  .partners-list .partner {
    width: 100%;
    height: 320px;
    margin-bottom: 24px;
  }
  .page-header .wrapper {
    padding: 48px 32px;
  }
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 96px;
  }
  .top-section .container .content h3 {
    font-size: 48px;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 28px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 21px;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 40px;
  }
  .section-title h5 {
    font-size: 24px;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 72px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 21px;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 48px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 21px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 18px;
  }
}
</style>
